import React, { useState, useEffect } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import LandingHeader from '../components/LandingHeader';
import Top20px from '../screens/Top20px';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';
import HomamslotCard from './HomamslotCard';
import { Label } from '@mui/icons-material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FooterComponent from '../components/footer';

import axios from 'axios';
import Swal from "sweetalert2";

const GanapathiHomam = () => {

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        const localId = localStorage.getItem("SD_Id") || localStorage.getItem("premiumVerified");
        if (localId) {
            axios.get(`https://backend.shubadinam.com/checkuser?userId=${localId}`)
                .then((response) => {
                    // console.log(response.data);
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'User not found !',
                        text: 'Please login to continue .',
                        icon: 'Info',
                        confirmButtonText: 'Okay',
                    })
                    navigate("/")
                })
        }
        else {
            Swal.fire({
                title: 'User not found !',
                text: 'Please login to continue .',
                icon: 'info',
                confirmButtonText: 'Okay',
            })
            navigate("/")
        }

    }, [])

    const [serviceName, setServiceName] = useState(''); // State for serviceName
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const getDefaultValues = () => {
            const title = location.pathname.split('/').pop();

            switch (title) {
                case 'Avahanti-Homam-booking':
                    return { service: 10, age: 10, serviceName: 'Avahanti-Homam' };
                case 'Navagraha-Homam-booking':
                    return { service: 20, age: 20, serviceName: 'Navagraha-Homam' };
                case 'Ganapathi-Homam-booking':
                    return { service: 30, age: 30, serviceName: 'Ganapathi-Homam' };
                case 'Surya-Namaskaram-booking':
                    return { service: 40, age: 40, serviceName: 'Surya-Namaskaram' };
                default:
                    return { service: 30, age: 30, serviceName: 'Ganapathi-Homam-booking' };
            }
        };

        const { serviceName: defaultServiceName } = getDefaultValues();
        setServiceName(defaultServiceName); // Set serviceName using useState hook

        sessionStorage.removeItem("homam-options");
        sessionStorage.removeItem("added users");


    }, [location.pathname]);



    const [count, setCount] = useState(1);

    const handleCount = (event) => {
        setCount(event.target.value);
    };



    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleContinueClick = () => {
        const queryParams = new URLSearchParams({
            // selectedOption,
            count,
            serviceName,
            chosenDate,
            amount
        }).toString();

        navigate(`/homam-details?${queryParams}`);
    };

    const handleBack = (e) => {
        window.history.back();
    }


    const handleAmountChange = (amount) => {
        setAmount(amount);
    };

    const handleEventDate = (eventtime) => {
        // console.log(`Selected date: ${eventtime}`);
    };

    const isContinueDisabled = selectedOption === '';



    const [startDate, setStartDate] = useState(new Date()); // Start with current month
    const [chosenDate, setChosenDate] = useState(); // State for chosen date



    const navtoHomam = (selectedPage) => {
        switch (selectedPage) {
            case "10":
                navigate(`/Avahanti-Homam-booking`)
                break;
            case "20":
                navigate(`/Navagraha-Sukta-Parayanam-booking`)
                break;
            case "30":
                navigate(`/Ganapathi-Homam-booking`)
                break;
            case "40":
                navigate(`/Surya-Namaskaram-booking`)
                break;

            default:
                break;
        }
    }

    const allowedDates = [
        new Date(2024, 5, 25),  // June 25, 2024
        new Date(2024, 6, 24),  // July 24, 2024
        new Date(2024, 7, 23),  // August 23, 2024
        new Date(2024, 8, 21),  // September 21, 2024
        new Date(2024, 9, 31),  // October 31, 2024
        new Date(2024, 10, 19), // November 19, 2024
        new Date(2024, 11, 19), // December 19, 2024
        new Date(2025, 0, 17),  // January 17, 2025
        new Date(2025, 1, 16),  // February 16, 2025
        new Date(2025, 2, 18),  // March 18, 2025
        new Date(2025, 3, 17),  // April 17, 2025
        new Date(2025, 4, 16),  // May 16, 2025
    ];



    const isAllowedDate = (date) => {
        return allowedDates.some(allowedDate =>
            date.getFullYear() === allowedDate.getFullYear() &&
            date.getMonth() === allowedDate.getMonth() &&
            date.getDate() === allowedDate.getDate()
        );
    };

    const tileDisabled = ({ date, view }) => {
        const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + view, 1);
        const startMonth = monthToShow.getMonth();
        const startYear = monthToShow.getFullYear();

        if (date.getMonth() !== startMonth || date.getFullYear() !== startYear) {
            return true; // Disable dates not in the current month
        }

        // Disable dates not in the allowed set
        if (!isAllowedDate(date)) {
            return true; // Disable dates not in the allowed set
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates
        if (date < today) {
            return true;
        }

        return false; // Enable all other dates
    };


    const tileClassName = ({ date, view }) => {


        if (view === 'month') {

            const currentDate = new Date();
            const day = date.getDay();

            // Check if the date has passed
            const isPastDate = date < currentDate && date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() < currentDate.getDate();

            if (isPastDate && (isAllowedDate(date))) {
                return 'highlight-past';
            }

            if (isAllowedDate(date)) {
                return 'highlight';
            }
        }
        return null;
    };

    const [showCount, setShowCount] = useState(false)



    const handleDateChange = (selectedDate) => {
        const options = { weekday: 'long', year: '2-digit', month: '2-digit', day: '2-digit' };
        setChosenDate(selectedDate.toLocaleDateString('en-US', options));
        setShowCount(true)
    };

    const calendars = [];
    for (let i = 0; i < 3; i++) {
        const monthToShow = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
        calendars.push(
            <SwiperSlide key={i}>
                {/* <div> */}
                <h5>{monthToShow.toLocaleString('default', { month: 'long', year: 'numeric' })}</h5>
                <Calendar
                    key={i}
                    value={startDate.getMonth() === monthToShow.getMonth() ? startDate : monthToShow}
                    tileDisabled={({ date }) => tileDisabled({ date, view: i })}
                    onChange={handleDateChange}
                    tileClassName={tileClassName}
                    defaultView="month"
                    maxDetail="month"
                    minDetail="month"
                    showNavigation={false}
                />
                {/* </div> */}
            </SwiperSlide>
        );
    }





    return (
        <div className='homam-container'>
            <LandingHeader />
            <Top20px />
            <div className='homam-body'>
                <div className='homam-title'>
                    <b>Ganapathi Homam</b>
                    {/* <NavLink to='/booking-history'>Booking History</NavLink> */}
                </div>
                <br />

                <div className='homam-options'>
                    <b style={{ textAlign: "start", width: "100%" }}>Service Name</b>
                    <div className='homam-options2'>
                        <Box sx={{ width: 500 }}>
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="service-native">
                                    Choose other services
                                </InputLabel>
                                <NativeSelect
                                    value={30}
                                    onChange={(e) => navtoHomam(e.target.value)} // Update serviceName state on change
                                    inputProps={{
                                        name: 'service',
                                        id: 'service-native',
                                    }}
                                >
                                    <option value={10}>Avahanti Homam</option>
                                    {/* <option value={20}>Navagraha Homam</option> */}
                                    <option value={30}>Ganapathi Homam</option>
                                    <option value={40}>Surya Namaskaram</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>

                    </div>
                </div>
                <br />

                <div className='homam-calender'>
                    <h6 style={{ padding: "15px", fontWeight: "700" }}>Slot</h6>
                    <p style={{ fontWeight: '200', paddingLeft: "25px" }}>
                        (Note : Select the <span style={{ color: "red" }}>date</span>  from the calender below.)
                    </p>
                    <br />
                    <div className='homam-calender-table'>

                        <Swiper
                            slidesPerView={1}
                            centeredSlides={false}
                            slidesPerGroupSkip={1}
                            grabCursor={false}
                            keyboard={{
                                enabled: true,
                            }}
                            breakpoints={{
                                200: {
                                    slidesPerView: 1.2,
                                    slidesPerGroup: 1,
                                    centeredSlides: true
                                },
                                769: {
                                    slidesPerView: 1.5,
                                    slidesPerGroup: 2,
                                    centeredSlides: false
                                },
                                992: {
                                    slidesPerView: 1,
                                    slidesPerGroup: 2,
                                    centeredSlides: false
                                },
                                1024: {
                                    slidesPerView: 1.5,
                                    slidesPerGroup: 2,
                                    centeredSlides: false
                                },
                            }}
                            scrollbar={true}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                            className="mySwiper"
                        >


                            {calendars}
                        </Swiper>


                    </div>
                    <div className='homam-color-definer'>
                        <div className='color-descriptions'>
                            <i ><span className='color-box green'></span> Seat is available</i>
                            <i><span className='color-box red'></span> Quota is full</i>
                            <i><span className='color-box grey'></span> Not available</i>
                            {/* <i><span className='color-box blue'></span> Quota not released</i> */}
                        </div>
                        <div className='calender-slider'>

                        </div>
                    </div>
                </div>
                <br />

                {
                    showCount &&

                    <>


                        <div className='homam-ticket-count'>
                            <p style={{ fontWeight: '200', color: '#454545cc' }}>
                                ( Note :{" "}👑 <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , buy more than 1 slot to get 1   <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "}free </span>slot.) *
                            </p>
                            <br />
                            <div>
                                <label style={{ textAlign: "center", placeContent: "center", paddingRight: "50px" }}>Number of Slots :</label>
                                <FormControl variant="standard" sx={{ minWidth: 100, }}>
                                    {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={count}
                                        onChange={handleCount}
                                        label="Age"
                                    >

                                        {
                                            [1, 2, 3, 4, 5].map((count, index) => {
                                                return <MenuItem key={index} value={count}>{count}</MenuItem>
                                            })

                                        }

                                    </Select>
                                </FormControl>
                            </div>
                        </div>


                        <div className='homam-slot-cards'>
                            <HomamslotCard selectedOption={selectedOption} handleOptionChange={handleOptionChange} ticketCount={count} serviceName={serviceName} onEventDate={handleEventDate} choosedDate={chosenDate} onAmountChange={handleAmountChange} />

                        </div>

                    </>

                }
                <br />

                <div className='homam-note'>
                    <span>Note :</span>
                    <ol>
                        <li>  <p style={{ fontWeight: '200' }}>
                            <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , buy more than 1 slot to get 1   <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "}free </span>slot.
                        </p> </li>
                        <li><p style={{ fontWeight: '200' }}>
                            <span style={{ fontWeight: 'bold', color: '#1014ba' }}>Premium </span>customers , cost of 1 free slot  <span style={{ fontWeight: 'bold', color: '#e70000' }}>{" "} Rs.{(serviceName === "Surya-Namaskaram" ? 125 : 250)}  </span>/- will be reduced from total cost.
                        </p>   </li>
                    </ol>
                    <div className='homam-continue'>
                        <Button className='homam-continue-btns' onClick={() => handleBack()}>Back</Button>

                        {
                            isContinueDisabled ?
                                <Button className='homam-continue-btns' disabled  >Continue</Button>
                                :
                                <Button className="homam-continue-btns homam-ctn-btn" onClick={handleContinueClick} disabled={isContinueDisabled}>Continue</Button>
                        }

                    </div>
                </div>
                <br />
            </div>
            <FooterComponent />
        </div >
    );
};

export default GanapathiHomam;
