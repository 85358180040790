import React, { useEffect } from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "react-scroll-to-top";
import Top10px from "./Top10px";
import Top20px from "./Top20px";
import BottomSpace from "./BottomSpace"
import LandingHeader from "../components/LandingHeader"
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AboutScreen = () => {




    const navigate = useNavigate()

    // useEffect(() => {
    //     window.scrollTo(0, 0); // Scroll to the top when component mounts
    // }, []);

    const getStarted = () => {
        navigate("/")
    }




    return (

        <div>
            <HelmetProvider>
                <Helmet>
                    <title>About </title>
                    <meta name="description" content="About  - Janma Nakshatra/Tithi Birthday Reminder Service." />
                </Helmet>
            </HelmetProvider>
            <div className="overall-container-about">
                <ScrollToTop smooth />
                <div className="overall-percent">
                    <LandingHeader />
                    {/* <HeaderComponent></HeaderComponent> */}
                    <Top10px />
                    {/* <Top20px/> */}
                    <br />
                    <div className="center-container-about">

                        <>
                            {/* ======= About Us Section ======= */}
                            <section id="about" className="about">
                                <div className="container">
                                    <div className="section-title">
                                        <h2 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>About Us</h2>
                                    </div>
                                    <div className="row content">
                                        <div className="col-lg-6">
                                            <h4 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}><strong>Welcome to Shubadinam: Embracing Your Hindu Identity Through Janma Nakshatra (Birth Stars):</strong></h4>
                                            <br />
                                            <p style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                                Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism. Our mission is to illuminate the importance of celebrating Birth Stars, known as Janma Nakshatra / Janma Tithi. We firmly believe that comprehending and rejoicing in one's Birth Star is a potent means to connect with our Hindu identity and pay homage to the timeless wisdom passed down by our ancestors.
                                            </p>
                                            {/* <ul>
                                                <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="black" d="m11.602 13.76l1.412 1.412l8.466-8.466l1.414 1.415l-9.88 9.88l-6.364-6.365l1.414-1.414l2.125 2.125zm.002-2.828l4.952-4.953l1.41 1.41l-4.952 4.953zm-2.827 5.655L7.364 18L1 11.636l1.414-1.414l1.413 1.413l-.001.001z" /></svg> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                                                <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="black" d="m11.602 13.76l1.412 1.412l8.466-8.466l1.414 1.415l-9.88 9.88l-6.364-6.365l1.414-1.414l2.125 2.125zm.002-2.828l4.952-4.953l1.41 1.41l-4.952 4.953zm-2.827 5.655L7.364 18L1 11.636l1.414-1.414l1.413 1.413l-.001.001z" /></svg>Duis aute irure dolor in reprehenderit in voluptate velit</li>
                                                <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="black" d="m11.602 13.76l1.412 1.412l8.466-8.466l1.414 1.415l-9.88 9.88l-6.364-6.365l1.414-1.414l2.125 2.125zm.002-2.828l4.952-4.953l1.41 1.41l-4.952 4.953zm-2.827 5.655L7.364 18L1 11.636l1.414-1.414l1.413 1.413l-.001.001z" /></svg> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                                            </ul> */}
                                        </div>
                                        <div className="col-lg-6 pt-4 pt-lg-0">
                                            <h4 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}><strong>Our Journey of Rediscovery:</strong></h4>
                                            <br />
                                            <p style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                                As students, we were once influenced by a colonial hangover like many others. It was our exposure to Sanskrit Scholars and Astikas in our family that ignited a deep desire to preserve and celebrate the ancient wisdom within our Hindu traditions. Shubadinam was born from this longing.
                                            </p>
                                            <NavLink to="/pricing" className="btn-learn-more">Get Started</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </section>{/* End About Us Section */}

                            {/* ======= Why Us Section ======= */}
                            {/* <section id="why-us" className="why-us section-bg">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                                            <div className="content">
                                                <h3>Eum ipsam laborum deleniti <strong>velit pariatur architecto aut nihil</strong></h3>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                                                </p>
                                            </div>
                                            <div className="accordion-list">
                                                <ul>
                                                    <li>
                                                        <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span> Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                                        <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                                                            <p>
                                                                Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                                        <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                                            <p>
                                                                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span> Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                                                        <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                                            <p>
                                                                Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: 'url("assets/img/why-us.png")' }} data-aos-delay={150}>&nbsp;</div>
                                    </div>
                                </div>
                            </section> */}
                            {/* End Why Us Section */}

                            {/* ======= Skills Section ======= */}
                            <section id="skills" className="skills">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 d-flex align-items-center" data-aos-delay={100}>
                                            <img src={require("../images/about-us.png")} className="img-fluid" alt onContextMenu={(e) => e.preventDefault()} />
                                        </div>
                                        <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos-delay={100}>
                                            <h4 style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}><strong>Action, Not Just Gyaan (Knowledge):</strong></h4>
                                            <br />

                                            <p style={{ fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
                                                In our brainstorming sessions on various traditions and rituals, we stumbled upon “Birthday vs Janma dinam”. (<NavLink to="/why-us" style={{ textDecorationLine: "none" }}>Click here to read more about “Why Shubadinam?”</NavLink>)The most significant challenge we identified was the lack of knowledge about our Hindu Calendar (Panchang) within our generation. Remembering a ‘Birthday’ was easier than ‘Janma dinam’. Our solution: register once and receive reminders for life.


                                            </p>
                                            {/* <div className="skills-content">
                                                <div className="progress">
                                                    <span className="skill">HTML <i className="val">100%</i></span>
                                                    <div className="progress-bar-wrap">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                                <div className="progress">
                                                    <span className="skill">CSS <i className="val">90%</i></span>
                                                    <div className="progress-bar-wrap">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                                <div className="progress">
                                                    <span className="skill">JavaScript <i className="val">75%</i></span>
                                                    <div className="progress-bar-wrap">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                                <div className="progress">
                                                    <span className="skill">Photoshop <i className="val">55%</i></span>
                                                    <div className="progress-bar-wrap">
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </section>{/* End Skills Section */}



                        </>


                        {/* <h2 className="about-align" data-aos="fade-up">About Us</h2>
                        <br />
                        <ol>

                            <h2 className="letters-color-subhead-about" data-aos="slide-right">Welcome to Shubadinam.com: Embracing Your Hindu Identity Through Janma Nakshatra (Birth Stars)</h2>
                            <p className="letters-color-subhead1-about" data-aos="fade-up">Shubadinam is a small contribution towards the larger good of Retaining and Reclaiming the rich cultural heritage of Hinduism by highlighting the significance of celebrating the Birth Stars, known as Janma Nakshatras and Janma Tithi. We firmly believe that understanding and celebrating one's Birth Star(Janma Nakshatra)/Janma Tithi is a powerful way to connect with our Hindu identity and honor the timeless wisdom of our ancestors.</p>
                            <h2 className="letters-color-subhead-about">Our Journey of Rediscovery:</h2>
                            <p className="letters-color-subhead1-about" data-aos="fade-up">As students we were no different from the rest of the crowd influenced by colonial Hangover. Thanks to our exposure to Sanskrit Scholars and Astikas in our family a deep desire to preserve and celebrate the ancient wisdom embedded in our Hindu traditions was kindled. Shubadinam was born out of this desire. </p>
                            <h2 className="letters-color-subhead-about">Action not Gyaan:</h2>
                            <p className="letters-color-subhead1-about" data-aos="fade-up">We brain stormed on several tradition and rituals. One led to the other. We zeroed in on “Birthday vs JanmaDinam”. (Click here to read more about<NavLink to="/whyus"> “ Why Shubadinam.com?”</NavLink>
                                )
                                The biggest challenge we found was the lack of knowledge about our Hindu Calendar (Panchang) amidst our Generation. So remembering a 'Birthday' was more easy than 'JanmaDinam'. We decided to make it simple. Register once and get reminders for life.
                            </p>
                            <h2 className="letters-color-subhead-about">Empowering You to Celebrate Your Identity:</h2>
                            <p className="letters-color-subhead1-about" data-aos="fade-up">Our primary goal is to empower you to know and cherish the unique significance of your JanmaDinam that is signified by your Birth Star (Janma nakshatra)/Janma Tithi. Through our user-friendly Shubadinam finder, you can easily get notified of the real Birthday of yourself, friends and family. </p>
                            <h2 className="letters-color-subhead-about">Discover the Sacred Connection:</h2>
                            <p className="letters-color-subhead1-about" data-aos="fade-up">At Shubadinam, we believe that recognizing and celebrating your Birth Star (Janma nakshatra)/Janma Tithi is a sacred act of connection with the divine. By understanding the traits and qualities associated with your Nakshatra/Tithi, you can cultivate a deeper appreciation for your individuality and divine purpose. ( Click Here to read More on )<NavLink to="/meanings-of-janma-nakshatra"> “Meanings of Janma Nakshatra”</NavLink> Celebrate your JanmaDinam for Good Health, Longer Life, Ample Wealth, Great fame and spiritual Wisdom as you journey through the celestial influences that shape your life.
                            </p>
                            
                            <button className="getstart" onClick={()=>getStarted()}>Get Started</button>
                        </ol> */}

                    </div>
                    <BottomSpace />
                    {/* <div className="empty-container-footer-about"> */}
                    <FooterComponent></FooterComponent>
                    {/* </div> */}
                </div>
            </div>
        </div>

    )
}
export default AboutScreen;