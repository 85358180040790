import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faSquareWhatsapp, faSquareXTwitter, faSquareInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const FooterComponent = () => {



    const facebookURL = 'https://www.facebook.com/profile.php?id=61551460127861';
    const instagramURL = 'https://www.instagram.com/shubadinam/';
    const youtubeURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';
    const twitterURL = 'https://twitter.com/Shubadinam';
    const whatsappURL = 'https://www.youtube.com/channel/UCsNbE7QqnpgjI7cXOTM1mXA';





    const handleIconClick = (iconCount) => {
        // console.log('Icon clicked:', iconCount);
        if (iconCount === 2) {
            window.open(facebookURL, '_blank');
        }
        else if (iconCount === 3) {
            window.open(instagramURL, '_blank');
        }
        else if (iconCount === 4) {
            window.open(youtubeURL, '_blank');
        }
        else if (iconCount === 1) {
            window.open(twitterURL, '_blank');
        }
        else {
            // window.open(whatsappURL, '_blank');
        }

    };








    return (
        <>
            {/* ======= Footer ======= */}
            <footer id="footer">
                {/* <div className="footer-newsletter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4>Our Newsletter</h4>
                                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            </div>
                            <div className="col-lg-6">
                                <form action method="post">
                                    <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <a href="/">Home</a></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/about-us">About us</NavLink></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/faq">FAQs</NavLink></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/termsandconditions">Terms of service</NavLink></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/privacypolicy">Privacy policy</NavLink></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/why-us">Why Us</NavLink></li>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/contact-us">Contact Us</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/blogs">Blogs</NavLink></li>
                                    <li>
                                        <i className="bx bx-chevron-right" />
                                        <a href="https://shop.shubadinam.com/" target="_blank" rel="noopener noreferrer">Shop</a>
                                    </li>

                                    <li><i className="bx bx-chevron-right" /> <NavLink to="/dashboard">Find Janma Nakshatra /  Tithi</NavLink></li>
                                    {/* <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li> */}
                                    {/* <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4>Contact Us</h4>
                                <p>
                                    {/* Adambakkam,<br />
                                    Chennai,{" "}600 088<br />
                                    TamilNadu.<br /><br /> */}
                                    <strong>Phone:</strong> +91 8925251938<br />
                                    <strong>Email:</strong> info@shubadinam.com<br />
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-info">
                                <h3>About Shubadinam</h3>
                                <p>Shubadinam stands as a humble offering toward the noble cause of Retaining and Reclaiming the profound cultural legacy of Hinduism.</p>
                                <div className="social-links mt-3">
                                    <a className="twitter" ><i className="bx bxl-twitter" onClick={() => handleIconClick(1)} /></a>
                                    <a className="facebook"><i className="bx bxl-facebook" onClick={() => handleIconClick(2)} /></a>
                                    <a className="instagram"><i className="bx bxl-instagram" onClick={() => handleIconClick(3)} /></a>
                                    <a className="whatsapp"><i className="bx bxl-whatsapp" onClick={() => handleIconClick()} /></a>
                                    <a className="youtube"><i className="bx bxl-youtube" onClick={() => handleIconClick(4)} /></a>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        © Copyright <strong><span>Shubadinam</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        {/* All the links in the footer should remain intact. */}
                        {/* You can delete the links only if you purchased the pro version. */}
                        {/* Licensing information: https://bootstrapmade.com/license/ */}
                        {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-bootstrap-template-corporate-moderna/ */}
                        {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}

                        <NavLink to="/pricing-policy" style={{ marginRight: '10px' }}>Pricing policy</NavLink>
                        |
                        <NavLink to="/shipping" style={{ margin: '0 10px' }}>Shipping</NavLink>
                        |
                        <NavLink to="/refundandcancellationpolicy" style={{ marginLeft: '10px' }}>Refund and Cancellation</NavLink>


                    </div>
                </div>
            </footer>{/* End Footer */}

        </>
        // <div className="empty-container">




        //     <div className="footer-link">

        //         <div className="f-link1">
        //             <span className="links-title" style={{ color: "#cad2ee", fontSize: "16px", fontWeight: 700 }}>COMPANY</span>
        //             <div className="links-list">

        //                 <NavLink to="/" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Home</NavLink>
        //                 <NavLink to="/about-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>About Us</NavLink>
        //                 <NavLink to="/faq" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>FAQ</NavLink>
        //                 <NavLink to="/whyus" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Why Us</NavLink>
        //                 <NavLink to="/contact-us" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Contact Us</NavLink>

        //             </div>
        //             <div className="links-list">

        //             <NavLink to="/shipping" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Shipping</NavLink>

        //                 <NavLink to="/pricing-policy" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Pricing Policy</NavLink>
        //             </div>
        //         </div>
        //         <div className="f-link2">
        //             <span className="link-icons-title" style={{ color: "#cad2ee", fontSize: "16px", fontWeight: 700 }}>SOCIAL MEDIA</span>
        //             <div className="link-icons">
        //                 <FontAwesomeIcon icon={faFacebook} style={{ color: "#ffffff", fontSize: "20px", cursor: 'pointer' }} onClick={() => handleIconClick(1)} />
        //                 <FontAwesomeIcon icon={faSquareInstagram} style={{ color: "#ffffff", fontSize: "20px", cursor: 'pointer' }} onClick={() => handleIconClick(2)} />
        //                 <FontAwesomeIcon icon={faYoutube} style={{ color: "#ffffff", fontSize: "20px", cursor: 'pointer' }} onClick={() => handleIconClick(3)} />
        //                 <FontAwesomeIcon icon={faSquareXTwitter} style={{ color: "#ffffff", fontSize: "20px", cursor: 'pointer' }} onClick={() => handleIconClick(4)} />
        //                 <FontAwesomeIcon icon={faSquareWhatsapp} style={{ color: "#ffffff", fontSize: "20px", cursor: 'pointer' }}  />
        //             </div>
        //         </div>


        //     </div>

        //     <div className="footer-copyright">
        //         <div className="footerc1">
        //             <h5 className="copyright-style">Copyright © 2023 Shubadinam.com. All Rights Reserved</h5>

        //         </div>

        //         <div className="footerc2">
        //             <NavLink to="/termsandconditions" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Terms and Conditions</NavLink>

        //             <span style={{ color: "white", paddingRight: "10px", textIndent: "10px" }}>|</span>

        //             <NavLink to="/privacypolicy" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Privacy Policy</NavLink>

        //             <span style={{ color: "white", paddingRight: "10px", textIndent: "10px" }}>|</span>

        //             <NavLink to="/refundandcancellationpolicy" className={({ isActive }) => isActive ? "link hightlight-link" : "link"}>Refund and Cancellation Policy</NavLink>
        //         </div>
        //     </div>


        //     <div>

        //     </div>

        // </div>
    )
}
export default FooterComponent;