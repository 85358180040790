import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


const AdminAccess = () => {

    const navigate = useNavigate()


    const [adminDetails, SetAdminDetails] = useState({
        adminUserId: "",
        adminPassword: ""
    })

    const inputHandle = (event) => {
        SetAdminDetails({ ...adminDetails, [event.target.name]: event.target.value })
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default behavior (e.g., form submission)
            event.stopPropagation(); // Stop event propagation

            clickSubmit();
        }
    };

    const clickClose = () => {
        navigate
            ("/")
    }


    const clickSubmit = () => {
        // console.log(adminDetails);
        sessionStorage.setItem("adminId", adminDetails.adminUserId)

        const url = `https://backend.shubadinam.com/adminaccess?adminUserId=${adminDetails.adminUserId}&adminPassword=${adminDetails.adminPassword}`;

        axios.get(url)
            .then((response) => {
                const value = response.data
                if (value.message === "passcodefail") {
                    Swal.fire({
                        title: 'Authorization Revoked',
                        text: "Please provide valid password !",
                        icon: 'error'
                    });
                }
                else if (value.message === "no user") {
                    Swal.fire({
                        title: 'Authorization Revoked',
                        text: "User not found !",
                        icon: 'error'
                    });
                }
                else {
                    // console.log(value);
                    navigate("/adminpage")
                }

            })
            .catch((error) => {
                // console.log(error);
                Swal.fire({
                    // title: 'Email Verification',
                    text: "Authorization Revoked",
                    icon: 'error'
                });
            })
    }


    return (

        <div className="overall-container">

            <div className="adaccess-form-container">
                <label for="adaccess-adminUserId">Enter your AdminId</label>
                <input type='text' id='adaccess-adminUserId' placeholder='Enter your AdminId' name='adminUserId' className='adaccess-input-field' onChange={inputHandle} onKeyPress={handleEnterKey} />
                <br />
                <br />
                <label for="adaccess-adminPassword">Enter your Password</label>
                <input type='password' id='adaccess-adminPassword' placeholder='Enter your Password' name='adminPassword' className='adaccess-input-field' onChange={inputHandle} onKeyPress={handleEnterKey} />
                <br />
                <button type='submit' className='adaccess-submit-button' onClick={() => clickSubmit()}>Submit</button>
            </div>
            <button style={{ backgroundColor: "#ffd7d7", padding: "10px", borderRadius: "7px" }} onClick={() => clickClose()}>Back to home</button>
            <br />
        </div>

    );
};

export default AdminAccess;