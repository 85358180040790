import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';

const HomamEvents = () => {

    const navigate = useNavigate();

    const handleRouting = (homam) => {

        navigate(`/${homam}`)

    }




    return (
        <>
            <Card className='card-event-btn' sx={{ maxWidth: 200, boxShadow: "none", backgroundColor: "transparent" }} onClick={() => handleRouting("Avahanti-Homam-instructions")}>
                <CardActionArea >
                    <CardMedia
                        className='card-event-image'
                        component="img"
                        height="100"
                        src={require("../images/homamImages/avahanti.png")}
                        alt="green iguana"
                    />

                    <CardContent className='events-homam-title'>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important`, }}>
                            Avahanti Homam
                        </Typography>

                    </CardContent>
                </CardActionArea>

            </Card>

            <Card className='card-event-btn' sx={{ maxWidth: 200, backgroundColor: "transparent", boxShadow: "none", }} onClick={() => handleRouting("Ganapathi-Homam-instructions")}>
                <CardActionArea >
                    <CardMedia
                        className='card-event-image'
                        component="img"
                        height="100"
                        src={require("../images/homamImages/ganapathi.png")}
                        alt="green iguana"
                    />
                    {/* <CardMedia sx={{ height: 100, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#eff8ff" }}
                    ><StarIcon /></CardMedia> */}

                    <CardContent className='events-homam-title'>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important`, }}>
                            Ganapathi Homam
                        </Typography>

                    </CardContent>
                </CardActionArea>

            </Card>

            <Card className='card-event-btn' sx={{ maxWidth: 200, backgroundColor: "transparent", boxShadow: "none", }} onClick={() => handleRouting("Surya-Namaskaram-instructions")}>
                <CardActionArea >
                    <CardMedia
                        className='card-event-image'
                        component="img"
                        height="100"
                        src={require("../images/homamImages/suryanamaksar.png")}
                        alt="green iguana"
                    />

                    <CardContent className='events-homam-title'>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important`, }}>
                            Surya Namaskaram
                        </Typography>

                    </CardContent>
                </CardActionArea>

            </Card>

            {/* <Card className='card-event-btn' sx={{ maxWidth: 200, backgroundColor: "transparent", boxShadow: "none", }} onClick={() => handleRouting("Navagraha-Sukta-Parayanam-instructions")}>
                <CardActionArea >
                    <CardMedia
                        className='card-event-image'
                        component="img"
                        height="100"
                        src={require("../images/homamImages/homam-logo-2.png")}
                        alt="green iguana"
                    />

                    <CardContent className='events-homam-title'>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important`, }}>
                            Navagraha Sukta Parayanam
                        </Typography>

                    </CardContent>
                </CardActionArea>

            </Card> */}

            <Card className='card-event-btn' sx={{ maxWidth: 200, backgroundColor: "transparent", boxShadow: "none", }} onClick={() => handleRouting("pricing")}>
                <CardActionArea >
                    <CardMedia
                        className='card-event-image'
                        component="img"
                        height="100"
                        src={require("../images/homamImages/premium.png")}
                        alt="green iguana"
                    />

                    <CardContent className='events-homam-title'>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important`, }}>
                            Premium
                        </Typography>

                    </CardContent>
                </CardActionArea>

            </Card>
        </>
    );
};

export default HomamEvents;