import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';



const HomamEventPhone = () => {

    const navigate = useNavigate();

    const handleRouting = (page) => {

        navigate(`/${page}`)

        // const localId = localStorage.getItem('SD_Id') || localStorage.getItem('premiumVerified')
        // if (localId) {
        //     navigate(`/${page}`)
        // }
        // else {
        //     navigate('/signin')
        // }
    }

    return (
        <>
            <br />
            <Swiper
                className="homam-list-phone"
                // slidesPerView={2}
                loop={true}
                // spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 2, // Adjust the slidesPerView for very small screens
                        spaceBetween: 10,
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    // 1024: {
                    //     slidesPerView: 5,
                    //     spaceBetween: 50,
                    // },
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >
                <SwiperSlide className='ph-each-eventslide' onClick={() => handleRouting("Avahanti-Homam-instructions")}>
                    <div className="homam-ph-event-img">
                        <img src={require("../images/homamImages/avahanti.png")} />
                    </div>
                    <div className="homam-ph-event-title">
                        <h6>Avahanti Homam</h6>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='ph-each-eventslide' onClick={() => handleRouting("Ganapathi-Homam-instructions")}>
                    <div className="homam-ph-event-img">
                        <img src={require("../images/homamImages/ganapathi.png")} />
                    </div>
                    <div className="homam-ph-event-title">
                        <h6>Ganapathi Homam</h6>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='ph-each-eventslide' onClick={() => handleRouting("Surya-Namaskaram-instructions")}>
                    <div className="homam-ph-event-img">
                        <img src={require("../images/homamImages/suryanamaksar.png")} />
                    </div>
                    <div className="homam-ph-event-title">
                        <h6>Surya Namaskaram</h6>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide className='ph-each-eventslide' onClick={() => handleRouting("Navagraha-Sukta-Parayanam-instructions")}>
                    <div className="homam-ph-event-img">
                        <img src={require("../images/homamImages/homam-logo-2.png")} />
                    </div>
                    <div className="homam-ph-event-title">
                        <h6>Navagraha Sukta Parayanam</h6>
                    </div>
                </SwiperSlide> */}

                <SwiperSlide className='ph-each-eventslide' onClick={() => handleRouting("pricing")}>
                    <div className="homam-ph-event-img">
                        <img src={require("../images/homamImages/premium.png")} />
                    </div>
                    <div className="homam-ph-event-title">
                        <h6>Premium</h6>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
};

export default HomamEventPhone;