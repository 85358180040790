// import React, { useState, useEffect, useRef } from "react";
// import { NavLink, Navigate, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
// import { } from '@fortawesome/free-regular-svg-icons'
// import { } from "@fortawesome/fontawesome-free"

// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
// import Swal from 'sweetalert2';
// import Top20px from "../screens/Top20px";

// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Badge from '@mui/material/Badge';
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import { Cookie } from "@mui/icons-material";
// import { format } from 'date-fns';



// AOS.init({
//     duration: 1000, // Notice the single quotes around '2s'
// });



// const DashboardHeader = () => {

//     const [dashsignOut, setSignOut] = useState(false);
//     const [isLesser992, setisLesser992] = useState(false);

//     useEffect(() => {
//         AOS.refresh();

//         const auth = localStorage.getItem("isAuthenticated");
//         if (auth) {
//             setSignOut(true)
//         }

//         const handleResize = () => {
//             if (window.innerWidth < 768) {
//                 setisLesser992(true);
//             } else {
//                 setisLesser992(false);
//             }
//         };

//         handleResize();

//         window.addEventListener('resize', handleResize);

//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);





//     const [signupcontent, SetSignupContent] = useState(false)



//     const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

//     // const clientSecret = "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";

//     const navigate = useNavigate();

//     const sectionRef = useRef(null);

//     const howitworks = useRef(null)


//     const [offset, setOffset] = useState(0);
//     const [inView, setInView] = useState(false);

//     const handleScroll = () => {
//         setOffset(window.scrollY);

//         // Check if the content is in view
//         const element = document.querySelector('.parallax-content');
//         if (element) {
//             const rect = element.getBoundingClientRect();
//             setInView(rect.top < window.innerHeight);
//         }
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);


//     const [backendError, SetBackendError] = useState([])


//     const [email, setEmail] = useState('');
//     const [message, setMessage] = useState('');

//     const [flag, SetFlag] = useState(false)
//     const [name, SetName] = useState("")

//     const [dynamicInput, DynamicInputFunction] = useState({
//         userEmail: "",
//         userPassword: "",
//         userPhone: "",
//     })

//     const [errorForm, ErrorFormFunction] = useState({
//         userEmail: false,
//         userPassword: false,
//         userPhone: false
//     })

//     const [eyeIcon, UpdateEyeIcon] = useState(true)

//     const eyeOpenClose = (icon) => {
//         UpdateEyeIcon(icon)
//     }

//     const inputHandle = (event) => {
//         DynamicInputFunction({ ...dynamicInput, [event.target.name]: event.target.value })
//         setEmail(dynamicInput.userEmail)
//     }

//     const onSuccess = (response) => {
//         // SetName(response.profileObj);
//         console.log("success", response);
//         SetFlag(true)
//     }

//     const onFailure = (err) => {
//         console.log(err);
//     }



//     const [isSubmitClicked, setIsSubmitClicked] = useState(false);


//     const scrollToSection = () => {

//         navigate("/register")

//         // if (sectionRef.current) {
//         //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//         //     setIsOpen(false)
//         //     SetSignupContent(true)
//         //     setOpenMenu(false)
//         // }
//     };



//     const [isOpen, setIsOpen] = useState(false);
//     const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


//     const togglePopup = () => {
//         if (sectionRef.current) {
//             sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//             setIsOpen(!isOpen);
//         }
//         // setIsOpen(!isOpen);
//     };

//     // const handleMouseLeave = () => {
//     //     setIsOpen(false); // Set dynamic variable to false on mouse leave
//     // };

//     useEffect(() => {
//         const handleScrollfade = () => {
//             // Calculate the scroll position threshold based on screen width
//             const screenWidth = window.innerWidth;
//             let scrollThreshold;

//             if (screenWidth > 1300) {
//                 // For screens wider than 1300px, set a different threshold
//                 scrollThreshold = 3000;
//             } else {
//                 // For screens 1300px or narrower, use a different threshold
//                 scrollThreshold = 2000; // You can adjust this value
//             }

//             if (window.scrollY > scrollThreshold) {
//                 setIsOpen(false);
//             }
//         };

//         window.addEventListener('scroll', handleScrollfade);

//         return () => {
//             window.removeEventListener('scroll', handleScrollfade);
//         };
//     }, []);





//     // /////////////COUNT DOWN and CELEBRATION EFFECT//////////////////

//     const [inAugrate, SetInAugrate] = useState(true) // **********important dont forget to change this false to activate inaugrate

//     const [countdown, setCountdown] = useState(5);

//     const [celebrate, setCelebrate] = useState(false);



//     const startCountdown = () => {
//         if (countdown > 0) {
//             startCountdownRecursive(countdown);
//         }
//     };

//     const startCountdownRecursive = (currentCount) => {
//         if (currentCount > 0) {
//             const countdownTimeout = setTimeout(() => {
//                 setCountdown(currentCount - 1);
//                 startCountdownRecursive(currentCount - 1);
//             }, 1000);
//         } else {
//             SetInAugrate(true)
//             localStorage.setItem("inaugrated", "true")
//             localStorage.setItem("celebration", "true")

//             const celebrationKey = localStorage.getItem('celebration'); // Replace with your specific key

//             if (celebrationKey) {
//                 // If the key is found in local storage, trigger the celebration
//                 setCelebrate(true);

//                 // Remove the key from local storage after 4 seconds
//                 setTimeout(() => {
//                     localStorage.removeItem('celebration'); // Replace with your specific key
//                     setCelebrate(false);
//                 }, 4000);
//             }
//         }
//     }

//     useEffect(() => {
//         if (localStorage.getItem("inaugrated")) {
//             SetInAugrate(true)
//         }
//         window.scrollTo(0, 0); // Scroll to the top when component mounts



//     }, [])

//     // ///////////////////COUNT DOWN END and CEKEBRATION EFFECT END//////////////


//     // MENU BAR

//     const [isOpenMenu, setOpenMenu] = useState(false);


//     // ENTER BUTTON

//     const formRef = useRef(null);

//     const handleKeyPress = (e) => {
//         if (e.key === 'Enter' && !isSubmitClicked) {
//             e.preventDefault(); // Prevent form submission
//             // Trigger the submit button click
//             formRef.current.querySelector('.sumbit-font').click();
//         }
//     };

//     // dashboard signout
//     const signuptoSignout = () => {
//         setSignOut(false)
//         localStorage.clear();
//         sessionStorage.clear()
//         localStorage.removeItem("myDataKey")
//         localStorage.removeItem("myDataKey2")
//         localStorage.removeItem("isAuthenticated")
//         localStorage.removeItem("fkey")
//         localStorage.removeItem("homeauth")
//         // Navigate("/")
//     }

//     // const clickDashboard=()=>{
//     //     Navigate("/home")
//     // }


//     const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

//     const handleMobileNavToggle = () => {
//         setIsMobileNavOpen(!isMobileNavOpen);
//     };

//     const handleDropdownClick = (e) => {
//         e.preventDefault();
//         if (isMobileNavOpen && e.target.nextElementSibling) {
//             e.target.nextElementSibling.classList.toggle('dropdown-active');
//         }
//     };

//     const [birthdayCount, setBirthdayCount] = useState(0);
//     const [notificationNames, setNotificationNames] = useState([]);
//     const [invisible, setInvisible] = useState();




//     const [anchorEl, setAnchorEl] = useState(null);
//     const open = Boolean(anchorEl);

//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//         // setBirthdayCount(0);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const handleBadgeVisibility = () => {
//         setInvisible(!invisible);
//         if (!invisible) {
//             setBirthdayCount(0)
//         }
//         else {
//             // fetchBirthdayData()
//         }

//     };





//     useEffect(() => {
//         // Function to fetch data from the backend and update birthdayCount state
//         async function fetchBirthdayData() {

//             try {

//                 const testdata = localStorage.getItem("fkey");
//                 const email = atob(testdata).replace(/"/g, ''); // Decode and then remove quotes



//                 const url = `https://backend.shubadinam.com/api/getRelativeBirthdays?userEmail=${email}`;

//                 axios.get(url)
//                     .then((response) => {
//                         const data = response.data;

//                         // Get the date today
//                         const today = new Date();

//                         // Calculate the date 7 days after today
//                         const sevenDaysAfter = new Date();
//                         sevenDaysAfter.setDate(today.getDate() + 7);

//                         // Filter the data based on the condition
//                         const filteredData = data.filter(item => {
//                             // Convert relative birthday string to Date object
//                             const birthday = item.relDate
//                             // console.log(birthday);
//                             // Format dates as "dd-MM-yyyy"
//                             // const formattedBirthday = format(birthday, "dd-MM-yyyy");
//                             const formattedToday = format(today, "dd-MM-yyyy");
//                             const formattedSevenDaysAfter = format(sevenDaysAfter, "dd-MM-yyyy");
//                             // console.log(formattedToday);
//                             // console.log(formattedSevenDaysAfter);
//                             // console.log(formattedBirthday);

//                             // Check if the formatted birthday falls within the range
//                             return birthday >= formattedToday && birthday <= formattedSevenDaysAfter;
//                         });

//                         // Update state with filtered data
//                         setNotificationNames(filteredData);
//                         setBirthdayCount(filteredData.length);
//                     })
//                     .catch((error) => {
//                         console.log(error);
//                     });




//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         }


//         fetchBirthdayData();
//     }, []); // Empty dependency array to run the effect only once on component mount



//     return (
//         <>
//             {/* ======= Header ======= */}
//             <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
//                 <div className="container d-flex justify-content-between align-items-center">

//                     {/* <p>Notification Names:</p>
//                     <ul>
//                         {notificationNames.map((notification, index) => (
//                             <li key={index}>{notification.relName}</li>
//                         ))}
//                     </ul> */}

//                     <div className="logo">
//                         <NavLink to="/">
//                             <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
//                         </NavLink>
//                     </div>


//                     {/* notification process for phone */}

//                     {/* <div className="notify-phone" onClick={handleClick}>
//                         <Badge badgeContent={birthdayCount} color="info" size="small">
//                             <NotificationsIcon color="action" className="notify-bell" />
//                         </Badge>
//                     </div> */}

//                     <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
//                         <ul>

//                             <div>

//                                 <Menu
//                                     id="basic-menu"
//                                     anchorEl={anchorEl}
//                                     open={open}
//                                     onClose={handleClose}
//                                     MenuListProps={{
//                                         'aria-labelledby': 'basic-button',
//                                     }}
//                                 >
//                                     <ol>
//                                         {notificationNames.map((notification, index) => (

//                                             <li key={index}>
//                                                 <MenuItem onClick={handleClose}>
//                                                     {notification.relName}'s birthday falls on '{notification.relDate}'.
//                                                 </MenuItem>
//                                             </li>
//                                         ))}

//                                         {/* <MenuItem><FormControlLabel
//                                             sx={{ color: 'text.primary' }}
//                                             control={<Switch checked={!invisible} onChange={handleBadgeVisibility} />}
//                                             label="Show Notifications"
//                                         /></MenuItem> */}

//                                     </ol>
//                                 </Menu>
//                             </div>

//                             {/* notification process for desktop */}


//                             {/* <li className="notify-desktop" onClick={handleClick}>
//                                 <Badge badgeContent={birthdayCount} color="info" size="small" invisible={invisible}>
//                                     <NotificationsIcon color="action" className="notify-bell" />
//                                 </Badge>
//                             </li> */}

//                             <li><NavLink to="/my-profile" className={({ isActive }) => isActive ? "active" : ""}>My Profile</NavLink></li>

//                             <li><NavLink to="/about-us">About Us</NavLink></li>

//                             <li><NavLink to="/pricing">Premium</NavLink></li>
//                             {/* <li><NavLink to="/shop">Shop</NavLink></li> */}

//                             {
//                                 isLesser992 ?
//                                     <>

//                                         <li><NavLink to="/faq">FAQs</NavLink></li>
//                                         <li><NavLink to="/Blogs">Blogs</NavLink></li>
//                                         <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>

//                                     </>
//                                     :
//                                     <li className="dropdown">
//                                         <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
//                                         <ul>
//                                             <li className="dropdown">
//                                                 {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
//                                                 <ul>
//                                                     <li><a href="#">Deep Drop Down 1</a></li>
//                                                     <li><a href="#">Deep Drop Down 2</a></li>
//                                                     <li><a href="#">Deep Drop Down 3</a></li>
//                                                     <li><a href="#">Deep Drop Down 4</a></li>
//                                                     <li><a href="#">Deep Drop Down 5</a></li>
//                                                 </ul>
//                                             </li>
//                                             <li><NavLink to="/faq">FAQs</NavLink></li>
//                                             <li><NavLink to="/Blogs">Blogs</NavLink></li>
//                                             <li><NavLink to="/why-us">Why Us</NavLink></li>
//                                         </ul>

//                                     </li>
//                             }
//                             <li><NavLink to="/contact-us">Contact Us</NavLink></li>







//                             {/* <li> */}
//                             {dashsignOut ?
//                                 <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>
//                                 :
//                                 <li><NavLink to="/register" className="link">Sign Up</NavLink></li>
//                             }

//                             {/* </li> */}



//                         </ul>

//                         <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
//                     </nav>
//                 </div>
//             </header>
//             {/* End Header */}
//         </>
//     );
// };

// export default DashboardHeader;




import React, { useState, useEffect, useRef } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, fa1, fa2, fa3, faUser, faL } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from "@fortawesome/fontawesome-free"

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Swal from 'sweetalert2';
import Top20px from "../screens/Top20px";

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Cookie } from "@mui/icons-material";
import { format } from 'date-fns';


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { blue, pink } from "@mui/material/colors";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


AOS.init({
    duration: 1000, // Notice the single quotes around '2s'
});



const DashboardHeader = () => {

    const [dashsignOut, setSignOut] = useState(false);
    const [isLesser992, setisLesser992] = useState(false);

    useEffect(() => {
        AOS.refresh();

        const auth = localStorage.getItem("isAuthenticated");
        if (auth) {
            setSignOut(true)
        }

        const handleResize = () => {
            if (window.innerWidth < 992) {
                setisLesser992(true);
            } else {
                setisLesser992(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);





    const [signupcontent, SetSignupContent] = useState(false)



    const clientId = "516265231821-9n4dbqkgm349bl3a76j75lgeu19vv71h.apps.googleusercontent.com"

    // const clientSecret = "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";

    const navigate = useNavigate();

    const sectionRef = useRef(null);

    const howitworks = useRef(null)


    const [offset, setOffset] = useState(0);
    const [inView, setInView] = useState(false);

    const handleScroll = () => {
        setOffset(window.scrollY);

        // Check if the content is in view
        const element = document.querySelector('.parallax-content');
        if (element) {
            const rect = element.getBoundingClientRect();
            setInView(rect.top < window.innerHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [backendError, SetBackendError] = useState([])


    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [flag, SetFlag] = useState(false)
    const [name, SetName] = useState("")

    const [dynamicInput, DynamicInputFunction] = useState({
        userEmail: "",
        userPassword: "",
        userPhone: "",
    })

    const [errorForm, ErrorFormFunction] = useState({
        userEmail: false,
        userPassword: false,
        userPhone: false
    })

    const [eyeIcon, UpdateEyeIcon] = useState(true)

    const eyeOpenClose = (icon) => {
        UpdateEyeIcon(icon)
    }

    const inputHandle = (event) => {
        DynamicInputFunction({ ...dynamicInput, [event.target.name]: event.target.value })
        setEmail(dynamicInput.userEmail)
    }

    const onSuccess = (response) => {
        // SetName(response.profileObj);
        console.log("success", response);
        SetFlag(true)
    }

    const onFailure = (err) => {
        console.log(err);
    }



    const [isSubmitClicked, setIsSubmitClicked] = useState(false);


    const scrollToSection = () => {

        navigate("/register")

        // if (sectionRef.current) {
        //     sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        //     setIsOpen(false)
        //     SetSignupContent(true)
        //     setOpenMenu(false)
        // }
    };



    const [isOpen, setIsOpen] = useState(false);
    const [scrollThresholdPercentage, setScrollThresholdPercentage] = useState(10); // 10% initial threshold


    const togglePopup = () => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsOpen(!isOpen);
        }
        // setIsOpen(!isOpen);
    };

    // const handleMouseLeave = () => {
    //     setIsOpen(false); // Set dynamic variable to false on mouse leave
    // };

    useEffect(() => {
        const handleScrollfade = () => {
            // Calculate the scroll position threshold based on screen width
            const screenWidth = window.innerWidth;
            let scrollThreshold;

            if (screenWidth > 1300) {
                // For screens wider than 1300px, set a different threshold
                scrollThreshold = 3000;
            } else {
                // For screens 1300px or narrower, use a different threshold
                scrollThreshold = 2000; // You can adjust this value
            }

            if (window.scrollY > scrollThreshold) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScrollfade);

        return () => {
            window.removeEventListener('scroll', handleScrollfade);
        };
    }, []);





    // /////////////COUNT DOWN and CELEBRATION EFFECT//////////////////

    const [inAugrate, SetInAugrate] = useState(true) // **********important dont forget to change this false to activate inaugrate

    const [countdown, setCountdown] = useState(5);

    const [celebrate, setCelebrate] = useState(false);



    const startCountdown = () => {
        if (countdown > 0) {
            startCountdownRecursive(countdown);
        }
    };

    const startCountdownRecursive = (currentCount) => {
        if (currentCount > 0) {
            const countdownTimeout = setTimeout(() => {
                setCountdown(currentCount - 1);
                startCountdownRecursive(currentCount - 1);
            }, 1000);
        } else {
            SetInAugrate(true)
            localStorage.setItem("inaugrated", "true")
            localStorage.setItem("celebration", "true")

            const celebrationKey = localStorage.getItem('celebration'); // Replace with your specific key

            if (celebrationKey) {
                // If the key is found in local storage, trigger the celebration
                setCelebrate(true);

                // Remove the key from local storage after 4 seconds
                setTimeout(() => {
                    localStorage.removeItem('celebration'); // Replace with your specific key
                    setCelebrate(false);
                }, 4000);
            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem("inaugrated")) {
            SetInAugrate(true)
        }
        window.scrollTo(0, 0); // Scroll to the top when component mounts



    }, [])

    // ///////////////////COUNT DOWN END and CEKEBRATION EFFECT END//////////////


    // MENU BAR

    const [isOpenMenu, setOpenMenu] = useState(false);


    // ENTER BUTTON

    const formRef = useRef(null);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isSubmitClicked) {
            e.preventDefault(); // Prevent form submission
            // Trigger the submit button click
            formRef.current.querySelector('.sumbit-font').click();
        }
    };

    // dashboard signout
    const signuptoSignout = () => {
        setSignOut(false)
        localStorage.clear();
        sessionStorage.clear();
        navigate("/")
    }

    // const clickDashboard=()=>{
    //     Navigate("/home")
    // }


    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleDropdownClick = (e) => {
        e.preventDefault();
        if (isMobileNavOpen && e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('dropdown-active');
        }
    };

    const [birthdayCount, setBirthdayCount] = useState(0);
    const [notificationNames, setNotificationNames] = useState([]);
    const [invisible, setInvisible] = useState();




    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setBirthdayCount(0);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBadgeVisibility = () => {
        setInvisible(!invisible);
        if (!invisible) {
            setBirthdayCount(0)
        }
        else {
            // fetchBirthdayData()
        }

    };


    useEffect(() => {
        // Function to fetch data from the backend and update birthdayCount state
        async function fetchBirthdayData() {

            try {

                const testdata = localStorage.getItem("fkey");
                const email = atob(testdata).replace(/"/g, ''); // Decode and then remove quotes



                const url = `https://backend.shubadinam.com/api/getRelativeBirthdays?userEmail=${email}`;

                axios.get(url)
                    .then((response) => {
                        const data = response.data;

                        // Get the date today
                        const today = new Date();

                        // Calculate the date 7 days after today
                        const sevenDaysAfter = new Date();
                        sevenDaysAfter.setDate(today.getDate() + 7);

                        // Filter the data based on the condition
                        const filteredData = data.filter(item => {
                            // Convert relative birthday string to Date object
                            const birthday = item.relDate
                            // console.log(birthday);
                            // Format dates as "dd-MM-yyyy"
                            // const formattedBirthday = format(birthday, "dd-MM-yyyy");
                            const formattedToday = format(today, "dd-MM-yyyy");
                            const formattedSevenDaysAfter = format(sevenDaysAfter, "dd-MM-yyyy");
                            // console.log(formattedToday);
                            // console.log(formattedSevenDaysAfter);
                            // console.log(formattedBirthday);

                            // Check if the formatted birthday falls within the range
                            return birthday >= formattedToday && birthday <= formattedSevenDaysAfter;
                        });

                        // Update state with filtered data
                        setNotificationNames(filteredData);
                        setBirthdayCount(filteredData.length);
                    })
                    .catch((error) => {
                        console.log(error);
                    });




            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }


        fetchBirthdayData();
    }, []); // Empty dependency array to run the effect only once on component mount


    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);

    const handleMouseEnter2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleMouseLeave2 = () => {
        console.log('Mouse has left the Box or Menu');

        setAnchorEl2(null);
    };

    const handleRouting = (page) => {
        // window.location.href = `/${page}`; // Set the URL to navigate to
        navigate(`/${page}`)
    }

    return (
        <>
            <header id="header" className={`fixed-top d-flex align-items-center header-mobile-open`}>
                <div className="container d-flex justify-content-between align-items-center">


                    <div className="logo">
                        <NavLink to="/">
                            <img src={require("../images/New Shubadinam Logo/Shubadinam White.png")} onContextMenu={(e) => e.preventDefault()} />
                        </NavLink>
                    </div>


                    <nav id="navbar" className={`navbar ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
                        <ul>

                            <div>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <ol>
                                        {notificationNames.map((notification, index) => (

                                            <li key={index}>
                                                <MenuItem onClick={handleClose}>
                                                    {notification.relName}'s birthday falls on '{notification.relDate}'.
                                                </MenuItem>
                                            </li>
                                        ))}

                                    </ol>
                                </Menu>
                            </div>


                            {
                                isLesser992 ?
                                    <>

                                        <li><NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ mr: "50%" }} fontSize="small" color="error" /></NavLink></li>

                                        <li><NavLink to="/my-profile" className={({ isActive }) => isActive ? "active" : ""}>My Profile</NavLink></li>

                                        <li><NavLink to="/pricing">Premium</NavLink></li>
                                        <li><NavLink to="/about-us" className={({ isActive }) => isActive ? "active" : ""}>About Us</NavLink></li>
                                        <li><NavLink to="/faq">FAQs</NavLink></li>
                                        <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                        <li><NavLink to="/why-us">Why Shubadinam</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>

                                        <li><NavLink to="/homam-bookings">My Bookings<EventAvailableRoundedIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>
                                        <li><NavLink to="/homam-bookings-history">Payment History<HistoryIcon sx={{ mr: "50%" }} fontSize="small" /></NavLink></li>

                                        {dashsignOut ?
                                            <li><a href="/" onClick={() => signuptoSignout()}>Sign Out</a></li>
                                            :
                                            <li><NavLink to="/register" className="link">Sign Up</NavLink></li>
                                        }

                                    </>

                                    :

                                    <>
                                        <li><NavLink to="/shubadinam-tv">Shubadinam TV<YouTubeIcon sx={{ ml: 1 }} fontSize="small" color="error" />
                                        </NavLink></li>
                                        <li><NavLink to="/about-us">About Us</NavLink></li>

                                        <li><NavLink to="/pricing">Premium</NavLink></li>
                                        <li className="dropdown">
                                            <a href="#" onClick={handleDropdownClick}><span>Info</span> <i className="bi bi-chevron-down" /></a>
                                            <ul>
                                                <li className="dropdown">
                                                    {/* <a href="#" onClick={handleDropdownClick}><span>Deep Drop Down</span> <i className="bi bi-chevron-right" /></a> */}
                                                    <ul>
                                                        <li><a href="#">Deep Drop Down 1</a></li>
                                                        <li><a href="#">Deep Drop Down 2</a></li>
                                                        <li><a href="#">Deep Drop Down 3</a></li>
                                                        <li><a href="#">Deep Drop Down 4</a></li>
                                                        <li><a href="#">Deep Drop Down 5</a></li>
                                                    </ul>
                                                </li>

                                                <li><NavLink to="/faq">FAQs</NavLink></li>
                                                <li><NavLink to="/Blogs">Blogs</NavLink></li>
                                                <li><NavLink to="/why-us">Why Us</NavLink></li>
                                            </ul>

                                        </li>

                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>



                                        <li className="dropdown" style={{ marginLeft: "25px", cursor: "pointer" }}>
                                            <Avatar
                                                onClick={handleDropdownClick}
                                                sx={{ width: 28, height: 28, color: "#020332", backgroundColor: "aliceblue" }} // Adjust size here
                                            />
                                            <ul className="d-header-menu">

                                                <li>


                                                    <MenuItem onClick={() => (handleRouting("my-profile"))}>
                                                        <ListItemIcon>
                                                            <AccountBoxIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        My Profile
                                                    </MenuItem>
                                                    <MenuItem onClick={() => (handleRouting("homam-bookings"))}>
                                                        <ListItemIcon>
                                                            <EventAvailableRoundedIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        My Bookings
                                                    </MenuItem>
                                                    <MenuItem onClick={() => (handleRouting("homam-bookings-history"))}>
                                                        <ListItemIcon>
                                                            <HistoryIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Payment History
                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={() => signuptoSignout()}>
                                                        <ListItemIcon>
                                                            <Logout fontSize="small" />
                                                        </ListItemIcon>
                                                        Sign Out
                                                    </MenuItem>
                                                </li>
                                            </ul>

                                        </li>


                                    </>
                            }








                        </ul>

                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNavToggle} />
                    </nav>
                </div >
            </header >
        </>
    );
};

export default DashboardHeader;