// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';

// const NonPremiumUsers = () => {
//     const [users, setUsers] = useState([]);
//     const [users2, setUsers2] = useState([]);
//     const [mergedUsers, setMergedUsers] = useState([]);

//     useEffect(() => {
//         allUsers();
//         allUsers2();
//     }, []);

//     useEffect(() => {
//         if (users.length > 0 && users2.length > 0) {
//             mergeUserData();
//         }
//     }, [users, users2]);

//     const allUsers = () => {
//         const url = `https://backend.shubadinam.com/get/nonpremiumusers`;
//         axios.get(url)
//             .then((response) => {
//                 setUsers(response.data);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };

//     const allUsers2 = () => {
//         const url = `https://backend.shubadinam.com/get/userdetails`;
//         axios.get(url)
//             .then((response) => {
//                 setUsers2(response.data);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };

//     const mergeUserData = () => {
//         const merged = users.map(user => {
//             const userDetails = users2.find(u => u.userEmail === user.userEmail) || {};
//             return { ...user, ...userDetails };
//         });
//         setMergedUsers(merged);
//     };

//     // console.log(mergedUsers);

//     const handleFilterChange = (e) => {
//         if (e.target.value === "10") {
//             const sortedUsers = [...mergedUsers].sort((a, b) => {
//                 const stateA = a.userState || '';
//                 const stateB = b.userState || '';
//                 if (stateA < stateB) {
//                     return -1;
//                 }
//                 if (stateA > stateB) {
//                     return 1;
//                 }
//                 return 0;
//             });
//             setMergedUsers(sortedUsers);
//         } else if (e.target.value === "0") {
//             mergeUserData(); // Reset to merged data
//         }
//     };

//     return (
//         <div style={containerStyle}>
//             <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Non-Premium users  </h3>
//             <br />
//             <Box sx={{ minWidth: 50 }}>
//                 <FormControl>
//                     <InputLabel variant="standard" htmlFor="uncontrolled-native">
//                         Filter
//                     </InputLabel>
//                     <NativeSelect
//                         defaultValue={0}
//                         inputProps={{
//                             name: 'filter',
//                             id: 'uncontrolled-native',
//                         }}
//                         onChange={handleFilterChange}
//                     >
//                         <option value={0}>All</option>
//                         <option value={10}>Sort by State</option>
//                     </NativeSelect>
//                 </FormControl>
//             </Box>
//             <br />
//             <table style={{ borderCollapse: 'collapse', width: '100%' }}>
//                 <thead>
//                     <tr>
//                         <th style={thStyle}> S.No</th>
//                         <th style={thStyle}>Name</th>
//                         <th style={thStyle}> ID</th>
//                         <th style={thStyle}> Email</th>
//                         <th style={thStyle}> Phone number</th>
//                         <th style={thStyle}>Signed On</th>
//                         <th style={thStyle}>Country</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {mergedUsers.map((user, index) => (
//                         <tr key={user.userId}>
//                             <td style={tdStyle}>{index + 1}</td>
//                             <td style={tdStyle}>{user.userName || 'N/A'}</td>
//                             <td style={tdStyle}>{user.uniqueId}</td>
//                             <td style={tdStyle}>{user.userEmail}</td>
//                             <td style={tdStyle}>{user.userPhone}</td>
//                             <td style={tdStyle}>
//                                 {new Date(user.entry_time).toLocaleString('en-GB', {
//                                     day: '2-digit',
//                                     month: '2-digit',
//                                     year: '2-digit',
//                                     hour: '2-digit',
//                                     minute: '2-digit',
//                                     hour12: true
//                                 })}
//                             </td>
//                             <td style={tdStyle}>{user.userCountry} / {user.userState}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// const containerStyle = {
//     padding: '20px',
//     backgroundColor: '#f7f7f7',
//     borderRadius: '8px',
// };

// const thStyle = {
//     backgroundColor: '#06022e',
//     border: '1px solid #dddddd',
//     padding: '10px',
//     textAlign: 'left',
//     color: "white",
// };

// const tdStyle = {
//     border: '1px solid #dddddd',
//     padding: '8px',
//     textAlign: 'left',
//     overflow: "auto"
// };

// export default NonPremiumUsers;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import AdminSearch from '../adminUtils/AdminSearch';


// Pagination Actions Component
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// Main Component
export default function NonPremiumUsers() {




    const [users, setUsers] = useState([]);
    const [users2, setUsers2] = useState([]);
    const [mergedUsers, setMergedUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        allUsers();
        allUsers2();
    }, []);

    useEffect(() => {
        if (users.length > 0 && users2.length > 0) {
            mergeUserData();
        }
    }, [users, users2]);

    const allUsers = () => {
        const url = `https://backend.shubadinam.com/get/nonpremiumusers`;
        axios.get(url)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const allUsers2 = () => {
        const url = `https://backend.shubadinam.com/get/userdetails`;
        axios.get(url)
            .then((response) => {
                setUsers2(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const mergeUserData = () => {
        const merged = users.map(user => {
            const userDetails = users2.find(u => u.userEmail === user.userEmail) || {};
            return { ...user, ...userDetails };
        });
        setMergedUsers(merged);
    };

    const handleFilterChange = (e) => {
        if (e.target.value === "10") {
            const sortedUsers = [...mergedUsers].sort((a, b) => {
                const stateA = a.userState || '';
                const stateB = b.userState || '';
                if (stateA < stateB) {
                    return -1;
                }
                if (stateA > stateB) {
                    return 1;
                }
                return 0;
            });
            setMergedUsers(sortedUsers);
        } else if (e.target.value === "0") {
            mergeUserData(); // Reset to merged data
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(0); // Reset page to 0 when search query changes
    };

    const filteredUsers = mergedUsers.filter(user =>
        (user.userName && user.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.uniqueId && user.uniqueId.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userEmail && user.userEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userPhone && user.userPhone.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.entry_time && user.entry_time.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userCountry && user.userCountry.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.userState && user.userState.toLowerCase().includes(searchQuery.toLowerCase()))
    );


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;

    return (
        <div>
            <h3 style={{ textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> Non-Premium users  </h3>
            <br />
            <Box sx={{ minWidth: 50, paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <FormControl>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Filter
                    </InputLabel>
                    <NativeSelect
                        defaultValue={0}
                        inputProps={{
                            name: 'filter',
                            id: 'uncontrolled-native',
                        }}
                        onChange={handleFilterChange}
                    >
                        <option value={0}>All</option>
                        <option value={10}>Sort by State</option>
                    </NativeSelect>
                </FormControl>
                <AdminSearch onSearch={handleSearch} />
            </Box>
            <br />


            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={7}
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                            select: {
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead >
                        <TableRow >
                            <TableCell style={{ width: 50, backgroundColor: "#0c0036", color: "white" }}>S.No</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Name</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">ID</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Email</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Phone number</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Signed On</TableCell>
                            <TableCell style={{ backgroundColor: "#0c0036", color: "white" }} align="right">Country</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.length > 0
                            ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index + 1 + page * rowsPerPage}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userName}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.uniqueId}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userEmail}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userPhone}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {new Date(user.entry_time).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        })}
                                    </TableCell>
                                    <TableCell style={{ width: 160, overflow: "auto" }} align="right">
                                        {user.userCountry}/{user.userState}
                                    </TableCell>
                                </TableRow>
                            ))
                            : null
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={7} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={7}
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

        </div>
    );
}
